<template>
  <div>
    <el-card>
      <el-container>
        <el-aside width="400px">
          <img style="width:100%" src="../../img/luckyDraw.png" alt="" />
        </el-aside>
        <el-container>
          <el-header height="100px">
            <!-- <el-tag
              size="medium"
              style="
          margin-bottom: 20px;
          line-height: 30px;
          height: 30px;
          text-align: center;
          font-size: 14px;
            "
              >基本信息</el-tag
            > -->
            
            <el-steps :active="toCalculate" align-center>
              <el-step title="基本信息"></el-step>
              <el-step title="奖品设置"></el-step>
            </el-steps>
            <div class="line"></div>
          </el-header>
          <el-main>
            <el-form
              :model="form"
              ref="form"
              :rules="rules"
              label-width="120px"
              :inline="false"
              size="small"
              v-show="toCalculate == 1"
            >
              <el-form-item label="活动名称" prop="name">
                <el-col :span="8">
                  <el-input v-model="form.name"></el-input>
                </el-col>
              </el-form-item>
              <el-form-item label="活动时间" prop="time">
                <el-col :span="8">
                  <el-date-picker
                    v-model="form.time"
                    type="datetimerange"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width: 100%"
                  />
                </el-col>
              </el-form-item>
              <el-form-item label="参与人数限制" prop="peopleLimit">
                <el-radio v-model="form.peopleLimit" :label="1" disabled
                  >不限制</el-radio
                >
                <el-radio v-model="form.peopleLimit" :label="2" disabled
                  >部分人可参与</el-radio
                >
              </el-form-item>
              <el-form-item label="参与次数" prop="number">
                <el-radio v-model="form.timesLimit" :label="1"
                  >一天N次</el-radio
                >
                <el-radio v-model="form.timesLimit" :label="2"
                  >一人N次</el-radio
                >
                <br />
                <div style="height:15px"></div>
                <el-input-number
                  :min="0"
                  v-model="form.number"
                ></el-input-number>
                <span> 次</span>
              </el-form-item>
              <el-form-item label="未中奖设置" style="font-weight:700;">
                <br />
                <el-form
                  label-width="80px"
                  :inline="false"
                  size="small"
                  style="font-weight:500;"
                  :model="form"
                  :rules="rules"
                >
                  <el-form-item label="名称" prop="failName">
                    <el-col :span="8">
                      <el-input v-model="form.failName"></el-input>
                    </el-col>
                  </el-form-item>
                  <el-form-item label="图片" prop="failPicture">
                    <Imgup
                      :action="action"
                      :banner="form.failPicture"
                      @event="setImg"
                      :tip_text="false"
                      :theCustom="'建议尺寸96x96像素，支持JPG、PNG、JPEG格式'"
                    ></Imgup>
                  </el-form-item>
                  <el-form-item label="提示语" prop="failMessage">
                    <el-col :span="8">
                      <el-input v-model="form.failMessage"></el-input>
                    </el-col>
                  </el-form-item>
                </el-form>
              </el-form-item>
              <el-form-item label="中奖名单" prop="resultShow">
                <el-radio v-model="form.resultShow" :label="0">不展示</el-radio>
                <el-radio v-model="form.resultShow" :label="1">展示</el-radio>
              </el-form-item>
              <el-form-item label="活动说明" prop="detail">
                <el-col :span="8">
                  <el-input
                    type="textarea"
                    :rows="2"
                    v-model="form.detail"
                  ></el-input>
                </el-col>
              </el-form-item>
              <!-- <p style="white-space: pre;">{{form.detail}}</p> -->
              <!-- <div class="line"></div> -->

              <!-- <el-form-item>
                <el-button
                  type="primary"
                  size="small"
                  @click="thePreviousStep"
                  :disabled="toCalculate == 1"
                  >上一步</el-button
                >
                <el-button type="primary" size="small" @click="theNextStep">{{
                  toCalculate == 3 ? '完成' : '下一步'
                }}</el-button>
              </el-form-item> -->
            </el-form>

            <div v-show="toCalculate == 2">
              <el-button @click="newPrizes" type="primary" size="small">{{
                '新增奖品'
              }}</el-button>
              <el-table
                :data="list"
                style="width: 100%"
                border
                stripe
                :header-cell-style="rowClass"
              >
                <!-- <el-table-column type="selection"></el-table-column> -->
                <el-table-column prop="location" label="位置">
                </el-table-column>
                <el-table-column prop="name" label="奖品"> </el-table-column>
                <el-table-column prop="phone" label="图片" width="120px">
                  <template slot-scope="{ row }">
                    <img
                      style="width:80px;"
                      :src="baseurl + yu + row.picture"
                      alt=""
                    />
                  </template>
                </el-table-column>
                <el-table-column prop="num" label="数量"> </el-table-column>
                <el-table-column prop="sendNum" label="已发放">
                </el-table-column>
                <el-table-column prop="awardName" label="中奖概率">
                  <template slot-scope="{ row }">
                    {{ row.probability }}%
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="{ row }">
                    <el-button
                      type="text"
                      icon="el-icon-edit"
                      size="mini"
                      @click="edit(row.id)"
                      class="skyblue"
                      >编辑</el-button
                    >
                    <el-button
                      type="text"
                      icon="el-icon-delete"
                      size="mini"
                      @click="open(row.id)"
                      class="red"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-main>
          <el-footer>
            <div class="line"></div>
            <el-button
              type="primary"
              size="small"
              @click="thePreviousStep"
              :disabled="toCalculate == 1"
              >上一步</el-button
            >
            <el-button type="primary" size="small" @click="theNextStep">{{
              toCalculate == 2 ? '完成' : '下一步'
            }}</el-button>
          </el-footer>
        </el-container>
      </el-container>
    </el-card>
    <el-drawer
      :title="(theText == '添加' ? '新增' : '编辑') + '奖品'"
      :visible.sync="drawer"
      :direction="'rtl'"
    >
      <el-form
        :model="form_2"
        ref="form_2"
        :rules="rules"
        label-width="120px"
        :inline="false"
        size="small"
      >
        <el-form-item label="奖品类型">
          <el-radio
            v-model="form_2.type"
            :label="item.key"
            v-for="(item, index) in configuration.awardType"
            :key="index"
            >{{ item.value }}</el-radio
          >
        </el-form-item>
        <el-form-item label="奖品名" prop="name">
          <!-- <el-col :span="8"> -->
          <el-input v-model="form_2.name"></el-input>
          <!-- </el-col> -->
        </el-form-item>
        <el-form-item label="奖品数量">
          <el-input-number :min="0" v-model="form_2.num"></el-input-number>
        </el-form-item>

        <el-form-item label="奖品出现位置">
          <el-input-number :min="0" v-model="form_2.location"></el-input-number>
        </el-form-item>
        <el-form-item label="奖品图片">
          <Imgup
            :action="action"
            :banner="form_2.picture"
            @event="setImg2"
            :tip_text="false"
            :theCustom="'建议尺寸96x96像素，支持JPG、PNG、JPEG格式'"
          ></Imgup>
        </el-form-item>
        <el-form-item label="中奖概率">
          <el-input-number
            :min="0"
            :max="100"
            v-model="form_2.probability"
          ></el-input-number
          >&emsp;%
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="add">{{
            theText
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>

<script>
import rules from '@/utils/rules.js'
export default {
  data() {
    return {
      theText: '添加',
      toCalculate: 1,
      drawer: false,
      form: {
        name: '',
        time: [],
        peopleLimit: 1,
        timesLimit: 1,
        number: 0,
        failName: '谢谢参与',
        failPicture: '/static/pc/img/4.png',
        failMessage: '谢谢参与',
        resultShow: 0,
        detail: ''
      },
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      list: [],
      id: 0,
      action: {
        action: 'rotary'
      },
      form_2: {
        type: 0,
        name: '',
        num: 0,
        location: 0,
        picture: '',
        probability: 0
      },
      configuration: {},
      rules: {
        name: [rules.req('请输入活动名称')],
        time: [rules.req('请输入活动时间')],
        peopleLimit: [rules.req('请输入参与人数限制')],
        number: [rules.req('请输入参与次数')],
        failName: [rules.req('请输入中奖名称')],
        failPicture: [rules.req('请输入中奖图片')],
        failMessage: [rules.req('请输入中奖提示语')],
        resultShow: [rules.req('请输入中奖名单')],
        detail: [rules.req('请输入活动说明')]
      }
    }
  },
  watch: {
    toCalculate(_newValue, oldValue) {}
  },
  methods: {
    thePreviousStep() {
      this.toCalculate--
    },
    rowClass() {
      return 'background:#F5F7FA;'
    },
    newPrizes() {
      this.form_2 = this.$options.data.call(this).form_2
      this.theText = '添加'
      this.drawer = true
    },
    setImg(val) {
      this.form.failPicture = val
    },
    setImg2(val) {
      this.form_2.picture = val
    },
    add() {
      this.$refs.form_2.validate(async val => {
        if (this.theText == '添加') {
          var { data: res } = await this.$http.post('/admin/Rotary/awardAdd', {
            ...this.form_2,
            rotaryId: this.id
          })
        } else {
          var { data: res } = await this.$http.post('/admin/Rotary/awardEdit', {
            ...this.form_2,
            rotaryId: this.id
          })
        }

        if (res.errorCode == 200) {
          this.$message.success(res.message)
          this.getlist()
          this.drawer = false
        } else {
          this.$message.error(res.message)
        }
      })
    },
    async edit(id) {
      this.theText = '保存'
      let { data: res } = await this.$http.post('/admin/Rotary/awardDetail', {
        id: id
      })
      if (res.errorCode == 200) {
        this.form_2 = res.data
        this.drawer = true
      } else {
        this.$message.error(res.message)
      }
    },
    open(id) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .post('/admin/Rotary/awardDel', {
              id: id
            })
            .then(res => {
              //   console.log(res)
              if (res.data.errorCode == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
                this.getlist()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.message
                })
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    async getlist() {
      let { data: res } = await this.$http.get('/admin/Rotary/getById', {
        params: {
          id: this.id
        }
      })
      this.list = res.data.award
      //   console.log(res)
    },
    async theNextStep() {
      if (this.toCalculate == 1) {
        this.$refs.form.validate(async val => {
          if (this.id) {
            var { data: res } = await this.$http.post('/admin/Rotary/edit', {
              ...this.form,
              id: this.id,
              beginTime: this.form.time ? this.form.time[0] : '',
              endTime: this.form.time ? this.form.time[1] : ''
            })
          } else {
            var { data: res } = await this.$http.post('/admin/Rotary/add', {
              ...this.form,
              beginTime: this.form.time ? this.form.time[0] : '',
              endTime: this.form.time ? this.form.time[1] : ''
            })
          }

          if (res.errorCode == 200) {
            this.$message.success(res.message)
            if (!this.id) {
              this.id = res.data.id
            }
            this.getlist()
            this.toCalculate++
          } else {
            this.$message.error(res.message)
          }
        })
      }
      if (this.toCalculate == 2) {
        this.$router.push('/rotary_list')
      }
      if (this.toCalculate > 2) {
        this.toCalculate = 2
      }
    },
    async getform() {
      let { data: res } = await this.$http.get('/admin/Rotary/getById', {
        params: {
          id: this.id
        }
      })
      this.form = {
        ...res.data,
        time: [res.data.beginTime, res.data.endTime]
      }
    },
    async theDictionary() {
      let { data: res } = await this.$http.get(
        '/admin/Common/getConfigDictionaries?config=rotary'
      )
      this.configuration = res.data
      //   console.log(this.configuration);
    }
  },
  components: {
    Imgup: () => import('@/components/upImg')
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getform()
    }
    this.theDictionary()
    // this.getlist()
  }
}
</script>

<style lang="less" scoped>
.line {
  width: 100%;
  background-color: #ddd;
  height: 1px;
  margin-bottom: 20px;
}
.skyblue {
  color: skyblue;
}
.green {
  color: green;
}
.yellow {
  color: pink;
}
.red {
  color: red;
}
</style>
